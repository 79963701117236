<template>
	<modal class="NoxModalPackageBuy" name="NoxModalPackageBuy" modal-width="600" :width="600" :height="'auto'" :adaptive="true" :scrollable="true" :clickToClose="true" @before-open="beforeOpen">
		<div class="nox_modal">
			<div class="nox_modal_head">
				<div class="nox_modal_close" @click="close"><font-awesome-icon :icon="['fas', 'times']" /></div>
				<div class="nox_modal_title" v-html="$store.getters.getLanguageText('5.3', 0, { name: noxPackageName })"></div>
			</div>
			<div class="nox_modal_body">
				<div class="nox_modal_info">
					<div v-if="!noxSponsorId">
						<div v-html="$store.getters.getLanguageText('5.3', 5)"></div>
						<div class="nox_modal_info_row last">
							<div class="nox_modal_info_row_label" v-html="$store.getters.getLanguageText('5.3', 6)"></div>
							<div class="nox_modal_info_row_value">
								<input type="text" class="nox_input" v-model.trim="noxInvitation" :placeholder="$store.getters.getLanguageText('5.3', 7)" autocomplete="off">
								<div v-html="noxAlertInvitation"></div>
							</div>
						</div>
					</div>
					<div v-else-if="noxPrevPackage && !noxIsSubscription" class="center" v-html="$store.getters.getLanguageText('5.3', 4, { name: noxPackageName })"></div>
					<div v-else-if="noxBuyStep == 1">
						<p><span v-html="$store.getters.getLanguageText('5.16', 7, { balance: $parent.$parent.$parent.getNumberFormatForDollar(noxBalance1, 2) })"></span><br><span v-html="$store.getters.getLanguageText('5.16', 8, { balance: $parent.$parent.$parent.getNumberFormatForDollar(noxBalance3, 2) })"></span><br><span v-html="$store.getters.getLanguageText('5.3', 1, { cost: parseFloat(noxPackageCost).toFixed(2), vat: noxSystemVat })"></span><br><span v-html="$store.getters.getLanguageText('5.3', 2, { cost: parseFloat(noxTotalCost).toFixed(2) })"></span></p>
						<p v-if="!noxIsBuy"><span class="red" v-html="$store.getters.getLanguageText('5.16', 12, { amount: parseFloat(noxTotalCost - noxBalance).toFixed(2) })"></span></p>
					</div>
					<div v-else-if="noxBuyStep == 2" v-html="$store.getters.getLanguageText('5.3', 3, { name: noxPackageName, amount: parseFloat(noxTotalCost).toFixed(2) })"></div>
				</div>
				<div class="nox_modal_alert" v-html="noxAlert"></div>
				<div class="nox_modal_loading" :class="{ hide: !noxIsLoading }"><img src="@/assets/images/loading.gif"></div>
				<div class="nox_modal_buttons" :class="{ hide: noxIsLoading }">
					<button type="button" class="nox_button common" @click="noxBuyStep = 1" v-if="noxBuyStep == 2" v-html="$store.getters.getLanguageText('1.1', 55)"></button>
					<button type="button" class="nox_button common" @click="close" v-else v-html="$store.getters.getLanguageText('1.1', 3)"></button>
					<button type="button" class="nox_button common green" @click="axios('addInvitation')" v-if="!noxSponsorId" v-html="$store.getters.getLanguageText('1.1', 64)"></button>
					<button type="button" class="nox_button common green" @click="close();$modal.show('NoxModalPackageExtend')" v-else-if="noxPrevPackage && !noxIsSubscription" v-html="$store.getters.getLanguageText('1.1', 36)"></button>
					<button type="button" class="nox_button common green" @click="noxBuyStep = 2" v-else-if="noxBuyStep == 1 && noxIsBuy" v-html="$store.getters.getLanguageText('1.1', 48)"></button>
					<button type="button" class="nox_button common green" @click="axios('buyPackage')" v-else-if="noxBuyStep == 2 && noxIsBuy" v-html="$store.getters.getLanguageText('1.1', 66)"></button>
					<button type="button" class="nox_button common green" @click="close();$modal.show('NoxModalBalance', { type: 'addMainBalance' })" v-else v-html="$store.getters.getLanguageText('1.1', 100)"></button>
				</div>
			</div>
		</div>
	</modal>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxTemp: '',
			noxAlert: '',
			noxAlertInvitation: '',
			noxBuyStep: 0,
			noxBalance: 0,
			noxBalance1: 0,
			noxBalance2: 0,
			noxBalance3: 0,
			noxSystemVat: 0,
			noxSponsorId: 0,
			noxInvitation: '',
			noxPrevPackage: 0,
			noxNextPackage: 0,
			noxPackageName: '',
			noxPackageCost: 0,
			noxTotalCost: 0,
			noxIsSubscription: 0,
			noxIsLoading: false,
			noxIsError: false,
			noxIsBuy: false
		}),
		methods: {
			beforeOpen: function(event) {

				this.noxNextPackage = event.params.package;
				this.initData();
			},
			resetAlerts: function() {

				this.noxAlert = '';
				this.noxAlertInvitation = '';
			},
			initData: function() {

				this.noxTemp = '';
				this.resetAlerts();
				this.noxBuyStep = 1;
				this.noxBalance1 = Number(this.$store.state.noxAccountData.balance_nox);
				this.noxBalance2 = Number(this.$store.state.noxAccountData.balance_nox_zp);
				this.noxBalance3 = Number(this.$store.state.noxAccountData.balance_of_bonuses);
				this.noxBalance = Math.round((this.noxBalance1 + this.noxBalance3) * 100) / 100;
				this.noxSystemVat = Number(this.$store.state.noxSystemSettings.vat_value);
				this.noxSponsorId = Number(this.$store.state.noxAccountData.sponsor_id);
				this.noxInvitation = '';
				this.noxPrevPackage = Number(this.$store.state.noxAccountData.package);
				this.noxPackageName = this.$store.state.noxSystemSettings['package_name_x' + this.noxNextPackage];
				this.noxPackageCost = Number(this.$store.state.noxSystemSettings['package_cost_x' + this.noxNextPackage]);
				this.noxPackageCost -= Number(this.$store.state.noxSystemSettings['package_cost_x' + this.noxPrevPackage]);
				this.noxTotalCost = Math.round((this.noxPackageCost * (1 + this.noxSystemVat / 100)) * 100) / 100;
				this.noxIsSubscription = Number(this.$store.state.noxAccountData.is_subscription);
				this.noxIsLoading = false;
				this.noxIsError = false;
				this.noxIsBuy = this.noxBalance >= this.noxTotalCost ? true : false;
			},
			getError: function(i) {

				this.noxIsError = true;

				if      (i == 1) { this.noxTemp = this.$store.getters.getLanguageText('1.3.3', 0); }
				else if (i == 2) { this.noxTemp = this.$store.getters.getLanguageText('1.3.3', 1); }
				else if (i == 3) { this.noxTemp = this.$store.getters.getLanguageText('1.3.3', 2); }
				else if (i == 4) { this.noxTemp = this.$store.getters.getLanguageText('1.3.3', 5); }
				else if (i == 5) { this.noxTemp = this.$store.getters.getLanguageText('1.3.3', 9); }
				else if (i == 6) { this.noxTemp = this.$store.getters.getLanguageText('1.3.3', 7); }
				else if (i == 7) { this.noxTemp = this.$store.getters.getLanguageText('1.3.3', 10); }
				else if (i == 8) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 23); }
				else if (i == 9) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 24); }
				else             { this.noxTemp = this.$store.getters.getLanguageText('1.3', 2); }

				return '<div class="alert"><b>' + this.$store.getters.getLanguageText('1.3', 0) + '</b> ' + this.noxTemp + '</div>';
			},
			axios: function(type) {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					_this.resetAlerts();
					_this.noxIsError = false;

					if (type == 'buyPackage') {
						_this.noxIsLoading = true;
						config.url = '/v2/account/users/package/buy';
						config.data = { package: _this.noxNextPackage };
						config.method = 'patch';
					}
					else if (type == 'addInvitation') {
						if (!_this.noxInvitation) {
							_this.noxAlertInvitation = _this.getError(8);
						}
						if (!_this.noxIsError) {
							_this.noxIsLoading = true;
							config.url = '/v2/account/users/invitation';
							config.data = { invitation: _this.noxInvitation.replace(/(.+)\?r=/gi, '') };
							config.method = 'patch';
						}
					}

					if (!_this.noxIsError) {
						axios(config).then(function(data) {
							if (data.status == 200) {
								if (type == 'buyPackage') {
									_this.$parent.$parent.$parent.initAccountData(data.data);
									_this.close();
								}
								else if (type == 'addInvitation') {
									_this.$parent.$parent.$parent.initAccountData(data.data);
									_this.initData();
								}
							}
						}).catch(function(data) {
							if (data.response !== undefined) {
								if (data.response.status == 400) {
									if (type == 'buyPackage') {
										if      (data.response.data.error == 'PACKAGE_IS_EMPTY') { _this.noxAlert = _this.getError(1); }
										else if (data.response.data.error == 'PACKAGE_NOT_VALID') { _this.noxAlert = _this.getError(2); }
									}
									else if (type == 'addInvitation') {
										if      (data.response.data.error == 'INVITATION_IS_EMPTY') { _this.noxAlertInvitation = _this.getError(8); }
										else if (data.response.data.error == 'INVITATION_NOT_VALID') { _this.noxAlertInvitation = _this.getError(9); }
									}
								}
								else if (data.response.status == 401) { _this.$parent.$parent.$parent.exit(); }
								else if (data.response.status == 409) {
									if (type == 'buyPackage') {
										if      (data.response.data.error == 'NOT_ENOUGH_MONEY') { _this.noxAlert = _this.getError(3); }
										else if (data.response.data.error == 'NEGATIVE_BALANCE') { _this.noxAlert = _this.getError(4); }
										else if (data.response.data.error == 'EXPIRED_SUBSCRIPTION') { _this.noxAlert = _this.getError(5); }
										else if (data.response.data.error == 'PACKAGE_ALREADY_ACTIVATED') { _this.noxAlert = _this.getError(6); }
										else if (data.response.data.error == 'SPONSOR_ID_IS_EMPTY') { _this.noxAlert = _this.getError(7); }
									}
								}
							}
							if (!_this.noxIsError) {
								_this.noxAlert = _this.getError(0);
							}
						}).finally(function() {
							_this.noxIsLoading = false;
						});
					}
				}
				else {
					_this.$parent.$parent.$parent.exit();
				}
			},
			close: function() {
				this.$modal.hide('NoxModalPackageBuy');
			}
		}
	}
</script>
